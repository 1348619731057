import { SURVEY_QUESTION_FULL_WITHOUT_SURVEY_FRAGMENT } from '@/graphql/_Fragments/SurveyQuestion/FullWithoutSurvey';

export const SURVEY_WITH_QUESTIONS_FRAGMENT = `
  fragment surveyWithQuestionsFragment on Survey {
    uid
    code
    title
    instructions
    startTime
    startTimestamp
    endTime
    endTimestamp
    questions(orderBy: displayOrder_asc) {
      ...surveyQuestionFullWithoutSurveyFragment
    }
  }
  ${SURVEY_QUESTION_FULL_WITHOUT_SURVEY_FRAGMENT}
`;
